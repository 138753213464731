import { MouseEvent, useState } from 'react';
import { useTheme } from '@mui/styles';
import { Popover, styled, useMediaQuery } from '@mui/material';
import { AppCardButton } from '../../shared/styled';
import { AppName } from '../../utils/types';
import { BRAINOS_MOBILE_QR, TENNANT_MOBILE_QR } from '../../utils/consts';

/**
 * Render a QR code in the popover under the mobile app card
 */
const MobileQRCode = ({
  appName,
  buttonText,
}: {
  appName: Extract<AppName, 'mobile' | 'mobileTennant'>;
  buttonText: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isMobile] = useState<boolean>(window.mobileCheck());
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    // use custom regex to check if browser is on mobile, otherwise fall back to breakpoint check
    if (isMobile || isSmallScreen) {
      // navigate directly to the mobile app store
      window.location.href =
        appName === 'mobile' ? BRAINOS_MOBILE_QR : TENNANT_MOBILE_QR;
    } else {
      // show the QR code popover
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'mobile-qr-code-popover' : undefined;

  return (
    <>
      <AppCardButton
        color="primary"
        variant="contained"
        aria-describedby={id}
        onClick={handleClick}
      >
        {buttonText}
      </AppCardButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        // note: only "background: transparent" works, not "backgroundColor: transparent"
        slotProps={{ paper: { sx: { background: 'transparent' } } }}
      >
        <QRCode
          src={
            appName === 'mobile'
              ? '/mobile-app-qr-code.png'
              : '/tennant-robotics-qr-code.png'
          }
          alt={
            appName === 'mobile'
              ? 'download the BrainOS mobile app'
              : 'download the Tennant Robotics mobile app'
          }
        />
      </Popover>
    </>
  );
};

const QRCode = styled('img')(() => ({
  maxWidth: '250px',
}));

export default MobileQRCode;
